<template>
  <v-menu v-model="menu" transition="fade-transition" :close-on-content-click="false" max-width="490" nudge-width="490"
    class="support-shadow" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" dark fab :x-small="$store.getters.mobile ? true : false" :small="!$store.getters.mobile ? true : false" color="primary" elevation="0" :title="$t('support')">
        <v-badge color="red" class="support-badge" :content="countUnreadMessages" :value="countUnreadMessages"
          offset-x="10" offset-y="10">
          <v-icon v-if="menu == false" size="18">mdi-headset</v-icon>
          <v-icon v-else size="18">mdi-close</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-list class="pa-0 text-center" v-if="!farewell && (!ticket || ticket.status === 'closed')">
      <ValidationObserver v-slot="{ invalid }" ref="form">
        <v-card height="auto" class="text-center">
          <v-app-bar flat color="primary">
            <v-toolbar-title class="white--text text-left pt-2">
              <img src="/assets/images/brand/white-horizontal-logo.svg" alt="Logo" width="50%" />
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- No Ticket | Schedule -->
            <div class="d-flex rounded-pill pa-1 white me-2" v-if="!supportAvailable">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab class="me-1" :title="$t('opening_hours')" elevation="0" small v-bind="attrs" v-on="on"
                    @click="$refs.schedules.renderItem()">
                    <v-icon color="primary" size="20">mdi-calendar-clock-outline</v-icon>
                  </v-btn>
                </template>
                <span class="text-center">{{ $t("opening_hours") }}</span>
              </v-tooltip>

              <v-btn fab class="me-1" :title="scheduleAlert ? $t('Mostrar Alerta') : $t('Ocultar Alerta')
                " elevation="0" small @click="toggleAlert">
                <v-icon color="warning" size="20">{{
                  scheduleAlert ? "mdi-eye-outline" : "mdi-eye-off-outline"
                }}</v-icon>
              </v-btn>
            </div>
            <!-- END | No Ticket | Schedule -->
            <div class="white pa-1 rounded-pill">
              <v-btn @click="menu = false" color="white" small fab>
                <v-icon color="primary">mdi-window-close</v-icon>
              </v-btn>
            </div>
          </v-app-bar>

          <!-- No Ticket | Schedule -->
          <v-card-text v-if="!supportAvailable && !scheduleAlert" class="pb-0">
            <v-alert dense text color="orange">
              Nos encontramos <strong>fuera del horario de atención</strong>, nuestro equipo te responderá lo antes
              posible.
              <v-row class="mt-1">
                <v-col>
                  <v-btn color="warning" block rounded elevation="0" @click="$refs.schedules.renderItem()"><v-icon left
                      color="white">mdi-calendar-clock-outline</v-icon>
                    Ver horarios</v-btn>
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>
          <!-- END | No Ticket | Schedule -->

          <v-card-text class="chat-content">

            <v-card color="white" height="auto">
              <v-card-title class="d-flex justify-center">
                <h4>{{ $t("chat_title") }}</h4>
              </v-card-title>
              <v-card-subtitle>
                {{ $t("complete_the_chat_form") }}
              </v-card-subtitle>
              <v-card-text class="pb-0">
                <v-row no-gutters>
                  <v-col cols="12" class="pb-0">
                    <ng-text v-model="form.name" label="name" rules="required" :disabled="true"></ng-text>
                    <ng-phone v-model="form.number" :disabled="!editContact" label="phone" rules="required"></ng-phone>
                    <v-btn v-if="contact" text block elevation="0" color="primary" @click="editContact = true"><v-icon
                        left>mdi-note-edit-outline</v-icon>{{ $t("edit_contact") }}</v-btn>
                    <ng-textarea v-model="form.message" label="message" class="pt-3" rules="required"></ng-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="green" :dark="!invalid" block :disabled="invalid"
                  @click="createTicketServiceOrSendMessage()">
                  <v-icon left>mdi-send</v-icon>
                  {{ $t("new_conversation") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-list>
    <v-list class="pt-0 mt-2 rounded bordered-chat" v-if="ticket && ticket.status !== 'closed'">
      <v-card height="auto">
        <v-app-bar flat color="primary">
          <v-toolbar-title class="white--text text-left pt-2">
            <img src="/assets/images/brand/white-horizontal-logo.svg" alt="Logo" width="50%" />
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Open Ticket | Schedule -->
          <div class="d-flex rounded-pill pa-1 white me-2" v-if="!supportAvailable">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab class="me-1" :title="$t('opening_hours')" elevation="0" small v-bind="attrs" v-on="on"
                  @click="$refs.schedules.renderItem()">
                  <v-icon color="primary" size="20">mdi-calendar-clock-outline</v-icon>
                </v-btn>
              </template>
              <span class="text-center">{{ $t("opening_hours") }}</span>
            </v-tooltip>

            <v-btn fab class="me-1" :title="scheduleAlert ? $t('Mostrar Alerta') : $t('Ocultar Alerta')
              " elevation="0" small @click="toggleAlert">
              <v-icon color="warning" size="20">{{
                scheduleAlert ? "mdi-eye-outline" : "mdi-eye-off-outline"
              }}</v-icon>
            </v-btn>
          </div>
          <!-- END | Open Ticket | Schedule -->
          <div class="white pa-1 rounded-pill">
            <v-btn @click="menu = false" color="white" small fab>
              <v-icon color="primary">mdi-window-close</v-icon>
            </v-btn>
          </div>
        </v-app-bar>
        <!-- Open Ticket | Schedule -->
        <v-card-text v-if="!supportAvailable && !scheduleAlert" class="pb-0">
          <v-alert dense text color="orange">
            Nos encontramos <strong>fuera del horario de atención</strong>, nuestro equipo te responderá lo antes
            posible.
            <v-row class="mt-1">
              <v-col>
                <v-btn color="warning" block rounded elevation="0" @click="$refs.schedules.renderItem()"><v-icon left
                    color="white">mdi-calendar-clock-outline</v-icon>
                  Ver horarios</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-card-text>
        <!-- END | Open Ticket | Schedule -->
        <v-card-text class="pa-0 ma-0 chat-content">
          <div class="chat" v-bind:elevation="'4'">
            <template>
              <div class="flex-grow-1 overflow-y-auto pa-2" style="height: 356px !important" id="chat-conversation">
                <template v-for="(msg, i) in messages">
                  <div v-bind:key="i" :class="{
                    'd-flex flex-row-reverse': msg.UserId ? false : true,
                  }">
                    <p :class="!msg.UserId
                      ? 'primary white--text chat-message pa-2 mb-2 rounded w-85'
                      : 'primary darken-4 white--text chat-message pa-2 mb-3 rounded w-85'
                      ">
                      {{ msg.body }}
                      <sub class="d-inline-block" style="font-size: 0.5rem">{{
                        $filters.date(msg.createdAt)
                      }}</sub>
                    </p>
                  </div>
                </template>
              </div>
              <div class="mt-2 pa-2">
                <v-text-field v-model="message" label="Escribe un mensaje [Enter]" type="text" no-details outlined
                  @keyup.enter="onSendMessage()" hide-details>
                  <template v-slot:append>
                    <v-icon @click="onSendMessage()" v-if="message.trim() !== ''" color="primary">mdi-send</v-icon>
                  </template>
                </v-text-field>
              </div>
            </template>
          </div>
        </v-card-text>
        <v-card-actions class="white d-flex justify-center" v-if="callInProgress">
          <div class="d-flex white rounded-pill pa-1" style="gap: 0.275em">
            <v-btn icon class="grey lighten-2" @click="unshareScreen()"
              v-if="!!screenShareTrack"><v-icon>mdi-monitor-off</v-icon></v-btn>
            <v-btn icon class="grey lighten-4" @click="shareScreen()" v-else><v-icon>mdi-monitor</v-icon></v-btn>
            <v-btn icon :disabled="disabledMic" class="grey lighten-4" @click="useMic"><v-icon>{{
              openMic ? "mdi-microphone" : "mdi-microphone-off"
                }}</v-icon></v-btn>
            <!-- <v-btn icon class="grey lighten-2"><v-icon>mdi-microphone-off</v-icon></v-btn> -->
            <v-btn icon class="red" dark @click="closeChat"><v-icon>mdi-phone-hangup</v-icon></v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-list>
    <v-list class="pa-2 overflow-hidden" v-if="farewell">
      <v-row>
        <v-col>
          <v-card height="auto" class="text-center overflow-y-auto" flat>
            <v-img src="/assets/dashboard/characters/3d_robot_heart.webp" class="float mx-auto mt-5"
              width="150"></v-img>
            <v-card-title class="d-flex flex-column justify-center align-center">
              <v-icon color="green" class="green--text" large>mdi-check-circle-outline</v-icon>
              <h5 class="green--text">{{ $t("success_chat") }}</h5>
            </v-card-title>
            <v-card-text>
              <p>
                Nos complace informarte que tu solicitud de soporte ha sido
                <b class="green--text">resuelta con éxito</b>.
              </p>
              <p>
                Estamos aquí para ti en cada paso del camino, y
                <b>tu satisfacción es nuestra máxima prioridad</b>. Si necesitas
                ayuda adicional o tienes alguna otra pregunta, no dudes en
                contactarnos en cualquier momento.
                <b>Estamos siempre dispuestos a ayudarte.</b>
              </p>
              <span class="overline primary--text">{{ $t("team_nube") }}</span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-list>
    <!-- <video-chat ref="video_chat" /> -->
    <receive-call-modal ref="receive_call" @confirm:call="confirmationCall" />
    <ScheduleModal :schedules="schedules" ref="schedules" />
    <audio autoPlay ref="partnerAudio" />
  </v-menu>
</template>

<script>
import { socket } from "./socket-support-chat";
// import * as moment from "moment";
import ReceiveCallModal from "./ReceiveCallModal.vue";
import MixinVideoChat from "./MixinVideoChat";
import ScheduleModal from "./ScheduleModal.vue";
export default {
  mixins: [MixinVideoChat],
  data() {
    return {
      menu: false,
      scheduleAlert: false,
      form: {},
      notificationSound: null,
      audio: null,
      socketChat: socket,
      ticket: null,
      contact: null,
      user: null,
      messages: null,
      countUnreadMessages: 0,
      message: "",
      supportAvailable: false,
      editContact: true,
      farewell: false,
      schedules: [],
    };
  },
  components: { ReceiveCallModal, ScheduleModal },

  mounted() {
    if (this.$store.getters.user) {
      this.user = this.$store.getters.user;
      this.setSupportChatInformation();
    }
    this.getSchedule();
    this.socketChat.on("getTicket", (data) => {
      if (!data || data.status === "closed") {
        this.messages = null;
        this.countUnreadMessages = 0;
        this.deleteTicket();
      }
    });
    this.socketChat.on("appMessage", (data) => {
      if (data.action === "create") {
        this.messages.push(data.message);
        if (this.menu) {
          this.scrollToBottom();
        }
        if (data.message.UserId) {
          this.$root.$emit("playNotificationSound");
        }
      }
    });

    this.socketChat.on("receive call", (userId, callId) => {
      this.receiveCallId = callId;
      this.otherUserId = userId;
      this.$refs.receive_call.renderItem();
    });
    this.socketChat.on("rejected call", (callId) => {
      this.$refs.receive_call.closeModal();
    });
    this.socketChat.on("ticket", (data) => {
      if (data.action === "create") {
        // this.setInfoTicket(data.ticket);
        // this.contact = data.ticket.contact;
        this.saveTicket(data.ticket, true);
      }
      if (data.action === "update") {
        // let ticket = data.ticket;
        // localStorage.removeItem("ticket_" + this.user.id);
        // localStorage.removeItem("contact_" + this.user.id);
        // this.setInfoTicket(ticket);
        this.saveTicket(data.ticket);
      }
      if (data.action === "delete") {
        this.deleteTicket();
        this.showFarewell();
        this.messages = null;
      }
    });
    this.socketChat.on("listMessageFromServer", (data) => {
      this.messages = data.messages;
    });
  },
  watch: {
    "$store.getters.user"(user) {
      this.user = user;
    },
    user(newValue, oldValue) {
      if (
        (newValue && !oldValue) ||
        (oldValue && newValue && newValue.id !== oldValue.id)
      ) {
        this.setSupportChatInformation();
      }
    },
    ticket(newValue, oldValue) {
      if (
        (newValue && !oldValue) ||
        (oldValue && newValue && newValue.id !== oldValue.id)
      ) {
        this.socketChat.emit("joinChatBox", newValue.id.toString());
        this.socketChat.emit("getTicketFromServer", newValue.id.toString());
        if (!this.messages) {
          this.socketChat.emit("getMessageOnServer", newValue.id.toString());
        }
      }
    },
    messages(newValue) {
      let count = 0;
      if (newValue && newValue[newValue.length - 1].UserId) {
        for (let message of newValue.toReversed()) {
          if (message.UserId) {
            count++;
          } else {
            break;
          }
        }
      }
      this.countUnreadMessages = count;
    },
    menu(newValue) {
      if (newValue && this.ticket) {
        this.scrollToBottom();
      }
    },
  },
  methods: {
    toggleAlert() {
      this.scheduleAlert = !this.scheduleAlert;
    },
    saveTicket(ticket, loading = false) {
      this.$http
        .post("api/v1/ticket/save", { ticket: ticket }, loading)
        .then((res) => {
          if (res.success) {
            this.setTicketStorage(res.item.ticket);
          }
        });
    },
    deleteTicket() {
      this.$http.post("api/v1/ticket/delete").then((res) => {
        if (res.success) {
          this.setTicketStorage(null);
        }
      });
    },
    isOnSchedule() {
      const date = new Date();
      // 0 = domingo
      // 1 = Lunes - 1
      let schedule = this.schedules[date.getDay() - 1];
      let time = this.parseTime(date.getHours(), date.getMinutes());
      if (schedule) {
        schedule = schedule.map((item) => {
          return parseInt(item.toString().replace(":", ""));
        });

        if (
          (time >= schedule[0] && time <= schedule[1]) ||
          (time >= schedule[2] && time <= schedule[3])
        ) {
          this.supportAvailable = true;
        }
        if (time >= schedule[0] && time <= schedule[3] + 30) {
          setTimeout(() => {
            this.isOnSchedule();
          }, 5 * 60 * 1000);
        }
      } else {
        this.supportAvailable = false;
      }
    },
    parseTime(hours, minutes) {
      if (minutes.toString().length < 2) {
        minutes = "0" + minutes.toString();
      }
      return parseInt(hours.toString() + minutes.toString());
    },
    setTicketStorage(ticket) {
      let user = this.user;
      if (ticket) {
        user.support_chat.contact = ticket.contact;
        this.contact = ticket.contact;
      }
      user.support_chat.ticket = ticket;
      this.ticket = ticket;
      this.user = user;
      this.$store.commit("setUser", user);
    },
    setSupportChatInformation() {
      this.ticket = this.user.support_chat.ticket;
      this.contact = this.user.support_chat.contact;
      if (this.contact) {
        this.form.name = this.contact.name;
        this.form.number = this.contact.number;
        this.editContact = false;
      }

      if (this.$store.getters.company) {
        this.form.name = this.$store.getters.company.name;
      }
      this.socketChat.emit("joinChatBoxWithUser", this.user.id);
    },
    setInfoTicket(ticket) {
      this.ticket = ticket;
      this.contact = ticket.contact;
    },
    getSchedule() {
      this.$http.get("api/support/schedule").then((res) => {
        if (res.success) {
          this.schedules = res.item;

          this.isOnSchedule();
        }
      });
    },
    createTicketServiceOrSendMessage(message = null) {
      const user = this.$store.getters.user;
      let mess = {
        text: message ? message : this.form.message,
        name: this.form.name,
        number: this.form.number,
        client_id: this.$store.getters.user.id,
      };
      if (this.ticket && this.ticket.status !== "closed") {
        mess.ticket_id = this.ticket.id;
      }
      if (!this.editContact && this.contact) {
        mess.contact_id = this.contact.id;
      }
      if (this.form.message !== "") {
        this.form.message = "";
      }
      this.socketChat.emit("message", mess);
    },
    scrollToBottom() {
      setTimeout(() => {
        const chatElement = document.querySelector("#chat-conversation");
        chatElement.scrollTo({
          top: chatElement.scrollHeight,
          behavior: "smooth",
        });
      }, 200);
    },
    clearDataCall() {
      this.receiveCallId = null;
      this.otherUserId = null;
    },
    onSendMessage() {
      if (this.message === "") {
        return;
      }
      this.createTicketServiceOrSendMessage(this.message);

      this.message = "";
    },

    showFarewell() {
      this.farewell = true;
      setTimeout(() => {
        this.farewell = false;
      }, 9000);
    },

    confirmationCall(boolean) {
      if (boolean) {
        this.socketChat.emit(
          "call accepted",
          this.receiveCallId,
          this.socketChat.id
        );
        this.initVideoChat();
      } else {
        this.socketChat.emit("call rejected", this.receiveCallId);
        this.clearDataCall();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 1366px) {
  .chat-content {
    height: 55vh;
    overflow-y: scroll;
  }
}

.w-85 {
  width: 85% !important;
}

.chat-message {
  max-width: max-content;
  line-break: anywhere;
}

.bordered-chat {
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  border-radius: 20px;
}
</style>