<template>
  <v-app>
    <!--v-main-->

    <router-view />
    <!--/v-main-->
    <!--  -->
    <v-dialog
      v-model="$store.getters.loading"
      persistent
      width="auto"
      class="pa-4"
      content-class="loader"
    >
      <div class="overflow-hidden">
        <v-row dense>
          <v-col cols="12" class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="white"
              :size="70"
              :width="7"
            ></v-progress-circular>
          </v-col>
          <v-col cols="12" class="d-flex justify-center">
            <span class="white--text text-h6">
              {{ $t("Cargando por favor espere...") }}
            </span>
          </v-col>
        </v-row>
      </div>
    </v-dialog>

    <template v-if="$store.getters.message != null">
      <v-dialog v-model="$store.getters.message" persistent max-width="400">
        <v-card class="pa-5 overflow-hidden">
          <v-card-text class="text-center">
            {{ $store.getters.message }}
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              outlined
              block
              color="green"
              @click="$store.commit('setMessage', null)"
              >Aceptar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-if="$store.getters.error">
      <v-dialog
        v-model="$store.getters.error"
        persistent
        max-width="981"
        width="auto"
      >
        <v-card class="pa-5 overflow-hidden">
          <v-card-text class="text-center">
            <div class="d-block">
              <v-icon
                class="red lighten-5 red--text text--darken-1 pa-3 mb-3 rounded-pill"
                size="40"
                >mdi-alert-circle</v-icon
              >
            </div>
            <span class="red--text">{{ $store.getters.error }}</span>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="red"
              @click="$store.dispatch('clearError')"
              outlined
              block
              >Aceptar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <iframe id="printf" name="printf" style="width: 1px; height: 1px"></iframe>

    <!--v-dialog v-model="nubeempresaIsOpen" persistent width="300">
      <v-card class="pa-3">
        <v-card-text style="color: red">
          Este sitio ya está abierto en otra pestaña o ventana. Por razones de
          seguridad y consistencia  solo puedes abrir una
          ventana para el POS. Este mensaje se ocultará cuando cierres las otras
          ventanas, en caso de no se cierre, puedes recargar el navegador.
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="red" @click="windowClose">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog-->

    <v-dialog v-model="openModalAgent" persistent width="450">
      <v-card class="overflow-hidden">
        <v-card-text class="text-center pa-5">
          <div class="d-block">
            <v-icon
              class="orange lighten-5 warning--text pa-3 rounded-pill"
              size="40"
              >mdi-alert-circle</v-icon
            >
          </div>
        </v-card-text>
        <v-card-text class="grey--text text-center">
          No se ha detectado el agente de impresión. Si ya lo has descargado,
          por favor ejecútalo. Si aún no lo has instalado,
          <b>descárgalo y ejecútalo</b> desde el botón descargar
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-center">
          <v-btn color="success" elevation="0">
            <a class="white--text v-btn" href="/agente.zip" target="_blank"
              >Descargar</a
            >
          </v-btn>
          <v-btn
            elevation="0"
            @click="openModalAgent = false"
            color="primary"
            outlined
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<style lang="scss">
.v-btn--is-elevated {
  box-shadow: none !important;
}

.loader {
  box-shadow: none !important;
}
</style>
<script>
import localAgent from "@/plugins/local-agent.js";
import appUtil from "@/plugins/app-utils.js";

export default {
  name: "App",
  data: () => ({
    openModalAgent: false,
    ws: null,
    document: null,
  }),
  async mounted() {
    /*let auth = localStorage.getItem("auth");
    if (auth) {
      auth = JSON.parse(auth);
      this.$store.commit("setAuth", auth);
      this.$currentUser.setData(auth);
      // console.log(this.$currentUser)
      // this.$store.dispatch('autoSignIn', auth)
    }*/
    /*let cities = await getCities();
    console.log(cities);*/

    /* setTimeout(() => {
      listenEvents(222, (doc)=> {
        console.log(doc)
        this.document = doc;
      })
    }, 1000);*/

    if (appUtil.isAppPos()) {
      this.validateAgent();
      setInterval(() => {
        this.validateAgent();
      }, 1 * 60 * 1000);
    }

    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  methods: {
    async validateAgent() {
      try {
        let useAgent = false;
        let installation = this.$store.getters.installation;
        if (installation) {
          const cashRegister = await this.$db.getCashRegister(
            installation.cash_id
          );
          if (cashRegister) {
            if (cashRegister.config) {
              if (cashRegister.config.pos_active_print_agent) {
                console.log("use agent");
                useAgent = true;
              }
            }
          }
        }
        if (useAgent) {
          // Realizar una solicitud GET a la API en localhost:5777/is-run
          const response = await localAgent.validateAgent();
          // Comprobar el código de estado de la respuesta (200 significa éxito)
          if (response.success == true) {
            console.log("Agent ok....");
            this.$store.commit("setAgent", true);
            //this.connectWebSocket();
          } else {
            console.log("Agent error....");
            this.openModalAgent = true;
            this.$store.commit("setAgent", false);
          }
        }
      } catch (e) {
        console.log("Agent error....");
        this.openModalAgent = true;
        this.$store.commit("setAgent", false);
      }
    },
    closeTab() {
      window.close();
    },
    onResize() {
      const screen = window.screen;
      const mobile = window.innerWidth < 960;

      const aspect_ratio = {
        screen_width: screen.width,
        screen_height: screen.height,
        aspect_ratio: this.calculateAspectRatio(screen.width, screen.height),
      };
      //console.log(aspect_ratio);
      this.$store.commit("setMobile", mobile);
      this.$store.commit("setAspectRatio", aspect_ratio);
    },
    nearestNormalAspectRatio(width, height, side) {
      let ratio = (width * 100) / (height * 100),
        maxW = 3 in arguments ? arguments[2] : 16,
        maxH = 4 in arguments ? arguments[3] : 16,
        ratiosW = new Array(maxW).join(",").split(","),
        ratiosH = new Array(maxH).join(",").split(","),
        ratiosT = {},
        ratios = {},
        match,
        key;

      ratiosW.forEach(function (empty, ratioW) {
        ++ratioW;

        ratiosH.forEach(function (empty, ratioH) {
          ++ratioH;

          let ratioX = (ratioW * 100) / (ratioH * 100);

          if (!ratiosT[ratioX]) {
            ratiosT[ratioX] = true;

            ratios[ratioW + ":" + ratioH] = ratioX;
          }
        });
      });

      for (key in ratios) {
        if (
          !match ||
          (!side &&
            Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])) ||
          (side < 0 &&
            ratios[key] <= ratio &&
            Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match])) ||
          (side > 0 &&
            ratios[key] >= ratio &&
            Math.abs(ratio - ratios[key]) < Math.abs(ratio - ratios[match]))
        ) {
          match = key;
        }
      }
      if (match == "8:5") {
        match = "16:10";
      }
      if (match == "8:3") {
        match = "16:9";
      }
      return match;
    },
    calculateAspectRatio(width, height) {
      return this.nearestNormalAspectRatio(width, height);
    },
  },
};
</script>
<style>
.form-default {
  width: 860px;
}
.support-chat {
  position: fixed;
  z-index: 1;
  right: -0.5vw;
  bottom: 4vw;
  margin: 8px;
}

@media (max-width: 768px) {
  .support-chat {
    position: fixed;
    z-index: 1;
    right: -1.5vw;
    bottom: 5vh;
    margin: 8px;
  }
}

.support-chat .v-badge__badge {
  background: white;
  outline: 2px solid var(--v-primary-lighten1);
  color: var(--v-secondary-lighten1);
}
</style>
