const axios = require('axios');

class AgentLocal {
  // Constructor para inicializar la clase si es necesario
  constructor() {
    // Aquí puedes inicializar headers predeterminados o configuraciones de axios si lo necesitas
  }

  async validateAgent() {
    try {
      let response = await this.get('https://localhost/is-run');
      // console.log(response)
      return response;
    } catch (e) {
      return null
    }
  }

  async save(content) {
    try {
      let response = await this.post('https://localhost/save', content);
      if (response.success) {
        return true
      }
      return false
    } catch (e) {
      return false
    }

  }

  async backup(content) {
    try {
      let response = await this.post('https://localhost/backup', content);
      if (response.success) {
        return true
      }
      return false
    } catch (e) {
      return false
    }

  }
  async print(content,  printer = 'POS58',width = '48mm') {
    try {
      let response = await this.post('https://localhost/print', { width: width, name: printer, body: content});
      if (response.success) {
        return true
      }
      return false
    } catch (e) {
      return false
    }

  }
  restore($db) {
    this.post('https://localhost/restore').then((response) => {
      if (response.success) {
        let content = JSON.parse(response.content)
        $db.loadDB(content)
      }
    })
  }

  // Método para realizar una petición GET
  get(url) {
    return axios.get(url)
      .then(response => {
        // Manejar la respuesta exitosa aquí
        return response.data;
      })
      .catch(error => {
        // Manejar el error aquí
        console.error('Error en la petición GET:', error);
        throw error;
      });
  }

  // Método para realizar una petición POST
  post(url, data) {
    return axios.post(url, data)
      .then(response => {
        // Manejar la respuesta exitosa aquí
        return response.data;
      })
      .catch(error => {
        // Manejar el error aquí
        console.error('Error en la petición POST:', error);
        throw error;
      });
  }
}

export default new AgentLocal();