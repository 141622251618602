import utilPrintTxt from './util/util-print-txt';
import utilPrintHtml from './util/util-print-html'; 
import localAgent from '@/plugins/local-agent.js'

class printManager {
    print_items = [];
    format = '';
    utilPrint = null;
    text = '';
    
    constructor(){
    }
    
    async printFromFormat(items, store, filters, db, type = 'receipt',printConfig = null){
      
		  let format = 'html';
      let modePrinter = 'browser';
      let fontSize = '15px';
      let installation = store.getters.installation;

		  
		  if (installation) {
			  const cashRegister = await db.getCashRegister(
			    installation.cash_id
			  );
        if(cashRegister){
          if(cashRegister.config){
            if(cashRegister.config.pos_printer_font_size){
              fontSize = cashRegister.config.pos_printer_font_size;
            }
            if(cashRegister.config.pos_printer_pos_receipt_is_thermal == false){
              format = 'text';
            }
            if(cashRegister.config.pos_active_print_agent){
              modePrinter = 'agent';
            }
          }
        }
		  }	

      this.print_items = items;
      this.format = format;
      if(this.format == 'html'){
        this.utilPrint = new utilPrintHtml(fontSize);
      }
      if(this.format == 'text'){
        this.utilPrint = new utilPrintTxt();
      }
      this.text = this.utilPrint.render(this.print_items);
      this.utilPrint.print(this.text, modePrinter, type, printConfig);      
    }   
    
    loadIframeImages (images) {
      let promises = []
    
      for (let image of images) {
        if (image.src && image.src !== window.location.href) promises.push(loadIframeImage(image))
      }
      promises = Array.prototype.slice.call(images).reduce((memo, image) => {
          if (image.src && image.src !== window.location.href) {
              memo.push(loadIframeImage(image));
          }
          return memo;
      }, []);
    
      return Promise.all(promises)
    }
}

export default new printManager();
