
function getValueConfiguration(conf) {
  if (conf.type_field == 'check') {
    if (conf.value_conf == 'true') {
      return true;
    } else {
      return false;
    }
  } else if (conf.type_field == 'number' || conf.type_field == 'money') {
    return parseFloat(conf.value_conf);
  }
  return conf.value_conf;
}

export default {
  state: {
    configurations: {},
    loading: false,
    error: null,
    message: null,
    mobile: false,
    aspect_ratio: null,
    drawer: false,
    user: null,
    menu: null,
    store: null, // sucursal en session para pantallas de cocina o meseros
    turn: null, // turno en session para pos se consulta en cada reload de la pagina 
    company: null,
    lists: new Map(),
    online: true,
    printData: null,
    installation: null,
    tables: [],
    agent: false,
    order_received: false,
    order_changed: 1,
    order_created: 1,
    filters: new Map(),

  },
  mutations: {
    setAgent(state, payload) {
      state.agent = payload
    },
    setUser(state, payload) {
      state.user = payload
    },
    setTurn(state, payload) {
      state.turn = payload
    },
    setCompany(state, payload) {
      state.company = payload
    },
    setStore(state, payload) {
      state.store = payload
    },
    setOrderReceived(state, payload) {
      state.order_received = payload
    },
    setOrderChanged(state, payload) {
      state.order_changed = state.order_changed + 1
    },
    orderChanged(state, val) {

      state.order_changed = state.order_changed + 1;
      //console.log('order change......................', state.orderChanged)
    },
    createdOrder(state, val) {

      state.order_created = state.order_created + 1;
      //console.log('order change......................', state.orderChanged)
    },
    setInstallation(state, payload) {
      state.installation = payload
    },
    setConfigurations(state, payload) {
      state.configurations = payload
    },
    updateConfiguration(state, cnf) {
      if (cnf.is_global) {
        state.configurations[cnf.name] = cnf;
      } else {
        if (cnf.require_subsidiary) {
          state.configurations[cnf.name + '_sub_' + cnf.bus_subsidiary_id] = cnf;
        } else {
          if (cnf.object_type != '') {
            state.configurations[cnf.name + '_' + cnf.object_type + '_' + cnf.object_id] = cnf;
          }
        }
      }
      //state.printData = payload;
    },
    setMessage(state, payload) {
      state.message = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setError(state, payload) {
      state.error = payload
    },
    clearError(state) {
      state.error = null
    },
    setAspectRatio(state, payload) {
      state.aspect_ratio = payload
    },
    setMobile(state, payload) {
      state.mobile = payload
    },
    setMenu(state, payload) {
      state.menu = payload
    },

    setDrawer(state, payload) {
      state.drawer = payload
    },
    toggle(state, payloa) {
      state.drawer = !state.drawer;
    },
    online(state, payloa) {
      state.online = payloa;
    },
    printData(state, payloa) {
      state.printData = payloa;
    },
    addList(state, list) {
      if (list && list.path) {
        state.lists.set(list.path, { items: list.items, last: (new Date()).getTime() });
      }
    },
    addFilter(state, filter) {
      if (filter && filter.path) {
        state.filters.set(filter.path, filter.values);
      }
    },
  },
  actions: {
    setConfigurations({ commit }, payload) {
      commit('setConfigurations', payload)
    },
    logoutAuth({ commit }) {
      //firebase.auth().signOut()
      localStorage.removeItem('filters_module');
      localStorage.removeItem('source_tk');
      localStorage.removeItem('sk');
      localStorage.removeItem('profile');
      commit('setUser', null)
      //console.log('logout')
      window.location.href = '/'
    },
    setOrderReceived({ commit }, payload) {
      commit('setOrderReceived', payload)
    },
    createdOrder({ commit }, payload) {
      commit('createdOrder', payload)
    },

    /*setOrderReceived({ commit }, payload) {
      commit('setOrderReceived', payload)
    },*/
    clearError({ commit }) {
      commit('clearError')
    },
    setError({ commit }, payload) {
      commit('setError', payload)
    },
    online({ commit }, payload) {
      commit('online', payload)
    },
    printData({ commit }, payload) {
      commit('printData', payload)
    },
    setInstallation({ commit }, payload) {
      commit('setInstallation', payload)
    },
    updateConfiguration({ commit }, payload) {
      commit('updateConfiguration', payload)
    },
    addFilter({ commit }, payload) {
      commit('addFilter', payload)
    }
  },
  getters: {
    agent: state => state.agent,
    configurations: state => state.configurations,
    installation: state => state.installation,
    getGeneralValue: (state) => (name) => {
      if (!state.installation) {
        return null;
      }
      if (!state.configurations[name]) {
        return null;
      }
      return getValueConfiguration(state.configurations[name]);
    },
    getSubsidiaryValue: (state) => (name) => {
      if (!state.installation) {
        return null;
      }
      let key = name + '_' + state.installation.subsidiary_id;
      if (!state.configurations[key]) {
        return null;
      }
      return getValueConfiguration(state.configurations[key]);
    },
   
    getPosValue: (state) => (name) => {
      //console.log(state.installation)
      if (!state.installation) {
        return null;
      }
      let key = name + '_\\App\\Models\\BillCashRegister' + '_' + state.installation.cash_id;
      if (!state.configurations[key]) {
        return null;
      }
      return getValueConfiguration(state.configurations[key]);
    },
    printData: state => state.printData,
    online: state => state.online,
    mobile: state => state.mobile,
    aspect_ratio: state => state.aspect_ratio,
    company: state => state.company,
    turn: state => {
      return state.turn
    },
    user: state => state.user,
    tables: state => state.tables,
    isRoot: state => {
      return state.user.rol.name == 'Root'
    },
    orderReceived: state => {
      return state.order_received
    },
    orderChanged: state => {
      return state.order_changed
    },
    orderCreated: state => {
      return state.order_created
    },

    store: state => {
      return state.store
    },
    isRestaurant: state => {
      return state.company && state.company.type.id == 2;
    },
    isDrugStore: state => {
      return state.company && state.company.type.id == 3;
    },
    menu: state => state.menu,
    drawer: state => state.drawer,
    getList: (state) => (path) => {
      if (!state.lists.has(path)) {
        return null;
      }
      return state.lists.get(path);
    },
    getFilter: (state) => (name) => {
      if (!state.filters.has(name)) {
        return {};
      }
      return state.filters.get(name);
    },
    message(state) {
      return state.message
    },
    loading(state) {
      return state.loading
    },
    error(state) {
      return state.error
    }
  }
}
