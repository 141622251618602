class utilReceiptPOS {

	logo = '';
	data_company = [];
	items_print = [];
	footernote = '';
	fontSize = "15";
	typeRender = 'html';
	to = null;
	totals = [];
	payments = [];
	reference = null;
	date = null;
	due_date = null;
	cashier = null;
	seller = null;
	domiciliary = null;
	table = null;
	title = null;
	currency = null;
	filters = null;
	store = null;
	msg_tip = null;
	turn_opening_date = null;
	turn_closing_date = null;

	constructor(store, fontSize, filters, db) {
		this.store = store;
		this.db = db;
		this.filters = filters;
		this.fontSize = fontSize;
		this.logo = '';
		this.data_company = [];
		this.items_print = [];
		this.footernote = '';
		this.typeRender = 'html';
		this.to = null;
		this.totals = [];
		this.payments = [];
		this.reference = null;
		this.date = null;
		this.due_date = null;
		this.cashier = null;
		this.seller = null;
		this.domiciliary = null;
		this.table = null;
		this.title = null;
		this.currency = null;
		this.msg_tip = null;
		this.turn_opening_date = null;
		this.turn_closing_date = null;

	}

	async addHeader(type = 'receipt') {
		if (this.data_company) {
			if (this.data_company['company_logo']) {
				// this.addImage(this.data_company['company_logo']);
				if (this.data_company['company_logo']) {
					let isFile = await this.fileExists(this.data_company['company_logo']);
					if (isFile) {
						const logo = await this.getimgBase64(this.data_company['company_logo']);
						if (logo) {
							this.addImage('' + logo);
						}
					}
				}
			}
			if (this.data_company['company_name']) {
				this.addText(this.data_company['company_name'], 'center', this.fontSize);
			}
			if (this.data_company['type_regimes']) {
				this.addText(this.data_company['type_regimes'], 'center', this.fontSize);
			}
			if (this.data_company['company_identification']) {
				this.addText(this.data_company['company_identification'], 'center', this.fontSize);
			}
			if (this.data_company['company_address']) {
				this.addText(this.data_company['company_address'], 'center', this.fontSize);
			}
			if (this.data_company['company_city']) {
				this.addText(this.data_company['company_city'], 'center', this.fontSize);
			}
			if (this.data_company['company_phone']) {
				this.addText(this.data_company['company_phone'], 'center', this.fontSize);
			}
			if (this.data_company['company_email']) {
				this.addText(this.data_company['company_email'], 'center', this.fontSize);
			}
			if (this.data_company['company_url']) {
				this.addText(this.data_company['company_url'], 'center', this.fontSize);
			}

			this.addLine();

			if (this.title) {
				this.addSpacing();
				this.addText(this.title, 'center', this.fontSize, 'fix_break');
			}

			if (this.reference) {
				this.addText(this.reference, 'left', this.fontSize);
			}

			if (this.date) {
				this.addText("Fecha: " + this.date, 'left', this.fontSize);
			}
			if (this.turn_opening_date) {
				this.addText("Apertura: " + this.turn_opening_date, 'left', this.fontSize);
			}
			if (this.turn_closing_date) {
				this.addText("Cierre: " + this.turn_closing_date, 'left', this.fontSize);
			}

			if (this.due_date) {
				this.addText("Vence el: " + this.date, 'left', this.fontSize);
			}
			if (this.cashier) {
				this.addText("Cajero: " + this.cashier, 'left', this.fontSize);
			}
			if (this.domiciliary) {
				this.addText("Domiciliario: " + this.domiciliary, 'left', this.fontSize);
			}
			if (this.seller) {
				this.addText("Vendedor: " + this.seller, 'left', this.fontSize);
			}
			if (this.table) {
				this.addText(this.table, 'left', this.fontSize);
			}
			if (this.headersText) {
				for (const head of this.headersText) {
					this.addText(head, 'left', this.fontSize);
				}
			}
			this.addLine();
		}
		if (this.to) {
			let name_customer = '';
			if (this.to['first_name']) {
				name_customer += this.to['first_name'];
			}
			if (this.to['last_name']) {
				name_customer += this.to['last_name'];
			}
			if (name_customer) {
				this.addSpacing();
				this.addText(name_customer, 'left', this.fontSize, 'fix_break');
			}
			if (this.to['identification']) {
				this.addText(this.to['identification'], 'left', this.fontSize);
			}
			if (this.to['address']) {
				this.addText(this.to['address'], 'left', this.fontSize);
			}
			if (this.to['phone']) {
				this.addText(this.to['phone'], 'left', this.fontSize);
			}
			if (this.activePoints()) {
				if (this.to['points_assigned']) {
					this.addText('Puntos asignados: ' + this.to['points_assigned'], 'left', this.fontSize);
				}
				if (this.to['points_used']) {
					this.addText('Puntos usados: ' + this.to['points_used'], 'left', this.fontSize);
				}
				if (this.to['current_points']) {
					this.addText('Puntos actuales: ' + this.to['current_points'], 'left', this.fontSize);
				}
			}

			this.addLine();
		} else {
			if (type == 'receipt') {
				this.addText('Sin Cliente', 'left', this.fontSize);
				this.addLine();
			}
		}


	}
	activePoints() {
		let activeModulePoints = this.store.getters.getGeneralValue(
			"gen_others_active_point_accumulation"
		);

		return activeModulePoints;
	}
	addTotals() {
		if (this.totals.length > 0) {
			this.addLine();
			for (const row of this.totals) {
				this.addTotal(row['label'], this.formatToCurrency(row['value']), row['font_size']);
			}
			this.addLine();
		}
	}

	addPayments() {
		if (this.payments.length > 0) {
			for (const row of this.payments) {
				this.addPayment(row['label'], this.formatToCurrency(row['value']), row['font_size']);
			}
			this.addLine();
		}
	}

	addFooter() {
		if (this.msg_tip) {
			this.addSpacing();
			this.addText(this.msg_tip, 'justify', 12);
			this.addSpacing();
		}
		if (this.footernote) {
			this.addSpacing();
			this.addText(this.footernote, 'justify', 12);
			this.addSpacing();
		}
		this.addLine();
		this.addText('Software Nube Empresa', 'center', 10, 'footer_text');
		this.addText('https://www.nubempresa.com', 'center', 10, 'footer_text');
		this.addText('Impreso el: ' + (new Date()).toLocaleString(), 'center', 10, 'footer_text');
	}

	addImage(image, width = '100%', height = "140px") {
		this.items_print.push({ 'type': 'image', 'value': image, 'width': width, 'height': height });
	}

	addImageBase64(image, width = '100%', height = "140px", format = "", clase = "") {
		this.items_print.push({ 'type': 'imageBase64', 'value': image, 'width': width, 'height': height, 'format': format, 'class': clase });
	}



	addLine() {
		this.items_print.push({ 'type': 'line' });
	}

	addTable(rows, add_check = false, font_size = 14) {
		this.items_print.push({ 'type': 'table', 'rows': rows, 'font_size': font_size, 'add_check': add_check });
	}

	pushTotal(label, value, font_size = 14) {

		if (!value) {
			value = 0;
		}
		this.totals.push({ 'label': label, 'value': value, 'font_size': font_size });
	}

	pushPayment(label, value, font_size = 14) {

		if (!value) {
			value = 0;
		}
		this.payments.push({ 'label': label, 'value': value, 'font_size': font_size });
	}

	addTotal(label, value, font_size) {
		this.items_print.push({ 'type': 'total', 'label': label, 'value': value, 'font_size': font_size });
	}

	addPayment(label, value, font_size) {
		this.items_print.push({ 'type': 'total', 'label': label, 'value': value, 'font_size': font_size });
	}

	addHeaderText(text) {
		this.headersText.push(text);
	}

	addSpacing() {
		this.items_print.push({ 'type': 'spacing' });
	}

	addText(text, align = 'left', font_size = '15', clase = '') {
		this.items_print.push({ 'type': 'text', 'value': text, 'align': align, 'font_size': font_size, 'class': clase });
	}

	getDataCompany() {
		return this.data_company;
	}

	async setDataCompany() {
		let company_address = "";
		let company_phone = "";
		let company_email = "";
		let company_name = "";
		if (this.store.getters.company) {
			company_address = this.store.getters.company.address;
			company_phone = this.store.getters.company.phone;
			company_email = this.store.getters.company.email;
			company_name = this.store.getters.company.name
		}

		const turn = this.store.getters.turn;
		if (turn) {
			let cash_register = await this.db.getCashRegister(turn.bill_cash_register_id);
			if (cash_register) {
				if (cash_register.subsidiary) {
					if (cash_register.subsidiary.name) {
						if (cash_register.subsidiary.name.toLowerCase() != 'principal') {
							company_name += ' - ' + cash_register.subsidiary.name;
						}
					}
					if (cash_register.subsidiary.email) {
						company_email = cash_register.subsidiary.email;
					}
					if (cash_register.subsidiary.phone) {
						company_phone = cash_register.subsidiary.phone;
					}
					if (cash_register.subsidiary.address) {
						company_address = cash_register.subsidiary.address;
					}
				}
			}
		}

		this.data_company['company_logo'] = process.env.VUE_APP_BASECONFIG_URL_PUBLIC_FILES + this.store.getters.company.logo;
		this.data_company['company_name'] = company_name;
		this.data_company['type_regimes'] = this.store.getters.company.regimen.name + this.store.getters.company['type-person'].name;
		this.data_company['company_identification'] = this.store.getters.company.identification + this.store.getters.company.verification_digit;
		this.data_company['company_address'] = company_address;
		this.data_company['company_city'] = '';
		this.data_company['company_phone'] = company_phone;
		this.data_company['company_email'] = company_email;
		this.data_company['company_url'] = this.store.getters.company.url;
	}

	setDate(value) {
		this.date = value;
	}

	setTurnOpeningDate(value) {
		this.turn_opening_date = value;
	}
	setTurnClosingDate(value) {
		this.turn_closing_date = value;
	}

	setDueDate(value) {
		this.due_date = value;
	}

	setCashier(value) {
		this.cashier = value;
	}

	setSeller(value) {
		this.seller = value;
	}

	setMsgTip(value) {
		this.msg_tip = value;
	}

	setDomiciliary(value) {
		this.domiciliary = value;
	}

	formatToCurrency(value, decimals = 0) {
		return this.filters.currency(value, decimals)
	}

	setFooternote(note) {
		this.footernote = note;
	}

	setLogo(logo) {
		this.logo = logo;
	}

	setReference(value) {
		this.reference = value;
	}

	render() {
		return this.items_print;
	}
	setItemsPrint(value){
		this.items_print = value;
	}
	setTable(value) {
		this.table = value;
	}

	setTitle(value) {
		this.title = value;
	}

	setTo(data) {
		this.to = data;
	}

	setTypeRender(value) {
		this.typeRender = value;
	}

	getTypeRender() {
		return this.typeRender;
	}

	async fileExists(fileUrl) {
		if (!fileUrl) {
			return false;
		}
		return new Promise(res => {
			const http = new XMLHttpRequest();
			http.open('HEAD', fileUrl, true);
			http.send();
			http.onload = () => {
				res(http.status != 404);
			};
			http.onerror = () => {
				res(false);
			};
		});
	}

	getimgBase64 = url => fetch(url)
		.then(response => response.blob())
		.then(blob => new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => resolve(reader.result)
			reader.onerror = reject
			reader.readAsDataURL(blob)
		}))

}

export default utilReceiptPOS;